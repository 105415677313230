.header-logo {
  float: left;
  align-items: center;
  display: flex;
  font-weight: 700;
}

.header-menu {
  justify-content: flex-end;
}

.header-logo-image {
  width: 32px;
  height: 32px;
  margin-right: 0.5rem;
}

.header-logo-title {
  letter-spacing: 1px;
  font-family: Barlow, Fira Sans, sans-serif;
  text-transform: uppercase;
  white-space: nowrap;
  color: var(--capact-white);
}

.header-menu.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover,
.header-menu.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu:hover,
.header-menu.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-open {
  background-color: var(--ant-primary-color);
}

.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  margin: 0 -20px;
  padding: 0 20px;
}
