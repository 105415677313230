.ant-descriptions-item-label {
  font-weight: bold;
}

.site-layout-background {
  background: #fff;
  border-radius: 6px;
}

.page-header {
  background: #fff;
}

.page-header.has-breadcrumb {
  padding-top: 24px;
}

.ant-layout-sider-trigger {
  background: rgba(0, 0, 0, 0.02);
  color: #001529;
}
