body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f2f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import "antd/dist/antd.variable.min.css";

:root {
  /* Capact colors */
  --capact-white: #fff;
  --capact-black: #000;
  --capact-blue-dark: #000c19;
  --capact-blue: #004c91;
  --capact-red: #d00000;
  --capact-orange-dark: #e85d04;
  --capact-orange: #f48c06;
  --capact-orange-light: #faa307;
  --capact-orange-lighter: #ffba0d;
  --capact-gray-lighter: #f5f5f5;
  --capact-gray-light: #ededed;
  --capact-gray-dark: #333;
}

/* Ant UI style overrides */

@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@700&family=Fira+Code&family=Fira+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

h1,
h2,
h3,
h4,
h5 {
  font-family: "Barlow", "Fira Sans", sans-serif;
}

body {
  font-family: "Fira Sans", sans-serif;
}
code,
kbd,
pre,
samp {
  font-family: "Fira Code", monospace;
}

:root {
  --ant-primary-1: #ededed !important;
}

.ant-layout-header,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: var(--capact-blue-dark);
}

.ant-card-meta-title {
  font-weight: 700;
}

.ant-card-actions > li > span > .anticon,
.ant-card-actions > li > span a:not(.ant-btn) {
  color: var(--capact-blue);
}

.content-bg-rounded {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.content-bg-rounded .ant-table {
  background: transparent;
}

.content-bg-rounded .ant-table table {
  border-radius: 6px;
}

.content-bg-rounded .ant-table-container,
.content-bg-rounded
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 6px;
}

.content-bg-rounded
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 6px;
}

.content-bg-rounded
  .ant-table-container
  table
  > tbody
  > tr:last-child
  td:first-child {
  border-bottom-left-radius: 6px;
}

.content-bg-rounded
  .ant-table-container
  table
  > tbody
  > tr:last-child
  td:last-child {
  border-bottom-right-radius: 6px;
}
